<template>
  <!-- <div> -->
  <v-app-bar app color="#111d5e" dark>
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        :src="require('../assets/cpc.jpeg')"
        transition="scale-transition"
        width="40"
      />
      <v-btn
        text
        min-width="100"
        style="font-size: 120%;"
        :to="{ name: 'Home' }"
        >Cape Projects</v-btn
      >
    </div>
    <v-spacer></v-spacer>
    <v-btn text :to="{ name: 'Progress' }">
      <span class="mr-2">Progress</span>
      <v-icon>mdi-percent</v-icon>
    </v-btn>
    <v-btn text :to="{ name: 'About' }">
      <span class="mr-2">About</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({}),
  components: {},
  methods: {
    // about() {
    //     this.$router.push({name: 'About'})
    // }
  }
};
</script>
