<template>
  <v-app>
    <Header />
    <v-main>
      <router-view />
      <!-- <Home /> -->
    </v-main>
  </v-app>
</template>

<script>
// import HomePage from "./components/HomePage";
import Header from "./components/Header";
// import Home from './views/Home';

export default {
  name: "App",
  components: {
    Header
    // Home
  },
  data: () => ({
    //
  }),
  mounted() {
    // this.$router.push({name:'Home'})
  }
};
</script>

<style>
/* <style> */
@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Montserrat&family=Raleway&display=swap");
#app {
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
h1,
h2,
h3 {
  font-family: Raleway;
  font-weight: bold;
}
html {
  background-color: whitesmoke;
}
span {
  font-family: Raleway;
}
</style>
